export const useMapIdsToData = () => {
  const getMemberName = (memberId?: string | null) => {
    const posStore = usePosStore();

    if (!posStore.pointOfSale) {
      throw new Error("Point of sale not found");
    }

    if (!memberId) {
      return posStore.pointOfSale?.organization.owner.fullName;
    }

    return (
      posStore.pointOfSale.organization?.organizationMembers.find(
        (member) => member.id === memberId
      )?.name ?? posStore.pointOfSale?.organization.owner.fullName
    );
  };

  const getReceiptPaymentDetails = (paymentMethodId?: string | null) => {
    const posStore = usePosStore();

    if (!posStore.pointOfSale) {
      throw new Error("Point of sale not found");
    }

    const paymentMethod =
      posStore.pointOfSale.organization?.receiptPaymentMethods.find(
        (paymentMethod) => paymentMethod.id === paymentMethodId
      );

    if (!paymentMethod) {
      return undefined;
    }

    return {
      displayName: paymentMethod?.displayName ?? "",
      displayNameTranslations: paymentMethod?.displayNameTranslations ?? null,
      cashAccountId: paymentMethod?.cashAccountId ?? null,
    };
  };

  const getContactInfo = (contactId?: string | null) => {
    const contactStore = useContactStore();

    const contact = contactStore.allContacts.find(
      (contact) => contact.id === contactId
    );

    return {
      contactName: contact?.contactName ?? "",
      contactNameTranslations: contact?.contactNameTranslations ?? null,
      contactTaxNumber: contact?.contactTaxNumber,
      contactCrNumber: contact?.contactCrNumber ?? undefined,
      contactAddress: contact?.address,
      contactAddressTranslations: contact?.addressTranslations,
    };
  };

  const getProductDetails = (productId?: string | null) => {
    const productStore = useProductStore();

    const product = productStore.products.find(
      (product) => product.id === productId
    );

    if (product == null) {
      throw new Error("Product not found");
    }

    return {
      productName: product.name ?? "",
      productNameTranslations: product?.nameTranslations ?? undefined,
      barcode: product.barcode ?? undefined,
      taxTypePercent: product.taxType?.taxPercent ?? undefined,
      taxTypeDisplayName: product.taxType?.displayName ?? undefined,
      taxTypeDisplayNameTranslations:
        product.taxType?.displayNameTranslations ?? undefined,
    };
  };

  return {
    getMemberName,
    getReceiptPaymentDetails,
    getContactInfo,
    getProductDetails,
  };
};
