import type { Invoice } from "~/composables/useIndexDB";

export default defineNuxtPlugin(async () => {
  let db: IDBDatabase | null = null;
  const DB_NAME = "pos-db";
  // bump version to trigger upgrade (onupgradeneeded)
  const DB_VERSION = 3;
  const MAX_RETRIES = 3;
  const RETRY_DELAY = 1000; // 1 second

  let DB_OLD_VERSION: number | null = null;

  const sleep = (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms));

  const initDB = (): Promise<IDBDatabase> => {
    return new Promise((resolve, reject) => {
      try {
        const request = indexedDB.open(DB_NAME, DB_VERSION);

        request.onerror = (event) => {
          console.error("Database error: ", event);
          reject(new Error("Failed to open database"));
        };

        request.onblocked = (event) => {
          console.error("Database blocked: ", event);
          reject(new Error("Database blocked"));
        };

        request.onupgradeneeded = (event) => {
          const db = (event.target as any).result as IDBDatabase;
          const oldVersion = event.oldVersion;
          const newVersion = event.newVersion;

          DB_OLD_VERSION = oldVersion;

          console.debug(
            `Database upgrade needed: ${oldVersion} -> ${newVersion}`
          );

          // all stores are created when the user freshly start on version 0

          // create stores for version 1
          // if the user is on version 1 already, this will be skipped
          if (oldVersion < 1) {
            db.createObjectStore("users", { keyPath: "id" });
            db.createObjectStore("settings", { keyPath: "posId" });
            db.createObjectStore("pos", { keyPath: "id" });
            db.createObjectStore("contacts", { keyPath: "id" });
            db.createObjectStore("pendingContacts", { keyPath: "id" });
            db.createObjectStore("products", { keyPath: "id" });
            db.createObjectStore("productCategories", { keyPath: "id" });
            db.createObjectStore("invoices", { autoIncrement: true });
            db.createObjectStore("pendingInvoices", { autoIncrement: true });
            db.createObjectStore("draftInvoices", { keyPath: "id" });
            db.createObjectStore("terminal", { autoIncrement: true });
            db.createObjectStore("dumpInvoices", { keyPath: "posId" });
          }

          // create stores for version 2
          // if the user is on version 2 already, this will be skipped
          if (oldVersion < 2) {
            db.createObjectStore("pricingPoliciesProducts", {
              keyPath: "id",
            });
          }
        };

        request.onsuccess = (event) => {
          console.debug("Database opened successfully");
          db = (event.target as any).result as IDBDatabase;

          db.onerror = (e) => {
            console.error(`Database error: ${e}`);
          };

          db.onversionchange = () => {
            if (db) {
              db.close();
              alert("Database is outdated, please reload the page.");
            }
          };

          resolve(db);
        };
      } catch (error) {
        console.error("Critical database error:", error);
        reject(error);
      }
    });
  };

  const connectWithRetry = async (
    retries = MAX_RETRIES
  ): Promise<IDBDatabase> => {
    try {
      return await initDB();
    } catch {
      if (retries > 0) {
        console.warn(
          `Database connection failed, retrying... (${retries} attempts left)`
        );
        await sleep(RETRY_DELAY);
        return connectWithRetry(retries - 1);
      }

      // If all retries failed, throw a fatal error
      const fatalError = new Error(
        "Failed to initialize IndexedDB after multiple retries. The application cannot continue."
      );
      throw fatalError;
    }
  };

  const backfillCurrenciesFix = async (db: IDBDatabase) => {
    const tx = db.transaction(
      ["invoices", "pendingInvoices", "products"],
      "readwrite"
    );

    // backfill currencies fix for invoices
    const invoicesObjectStore = tx.objectStore("invoices");
    const invoiceRequest = invoicesObjectStore.openCursor();
    await new Promise((resolve, reject) => {
      invoiceRequest.onsuccess = (event) => {
        const cursor = (event.target as any).result as IDBCursorWithValue;

        if (cursor) {
          const invoice = cursor.value;

          // update invoice products
          const salesInvoiceProducts = invoice.salesInvoiceProducts.data.map(
            (product: any) => ({
              productId: product.productId,
              quantity: product.quantity,
              description: product?.description,
              originalUnitPrice:
                product.originalPriceSubtotal / product.quantity / 100,
              actualUnitPrice:
                product.actualPriceSubtotal / product.quantity / 100,
              taxPercent: product.taxPercent,
              taxIsIncluded: false,
            })
          );

          // update invoice receipt usages
          const salesReceiptInvoiceUsages =
            invoice.salesReceiptInvoiceUsages?.data.map((usage: any) => ({
              ...usage,
              amount: usage.amount / 100,
              actualPaidAmount: usage.actualPaidAmount / 100,
              salesReceipt:
                usage?.salesReceipt?.data != null
                  ? {
                      ...usage.salesReceipt.data,
                      amount: usage.salesReceipt.data.amount / 100,
                      costSubtotal:
                        ((usage.salesReceipt.data as any)?.costSubtotal || 0) /
                        100,
                      taxSubtotal:
                        ((usage.salesReceipt.data as any)?.taxSubtotal || 0) /
                        100,
                    }
                  : undefined,
            }));

          cursor.update({
            orgId: invoice.orgId,
            posId: invoice.posId,

            createdAt: invoice.createdAt,
            issueDate: invoice.issueDate,
            dueDate: invoice.dueDate,

            reference: invoice.reference,
            isCash: invoice.isCash,
            type: "simple",
            customerId: invoice.customerId,

            isAnonymousCustomer: invoice.isAnonymousCustomer,
            callNumber: invoice?.callNumber,
            memberId: invoice?.memberId,
            isNotSynced: false,
            salesInvoiceProducts: salesInvoiceProducts,
            salesReceiptInvoiceUsages: salesReceiptInvoiceUsages ?? undefined,
          } satisfies Invoice);
          cursor.continue();
        } else {
          resolve(null);
        }
      };

      invoiceRequest.onerror = () => {
        reject(new Error("Failed to iterate over invoices"));
      };
    });

    // backfill currencies fix for pending invoices
    const pendingInvoicesObjectStore = tx.objectStore("pendingInvoices");
    const pendingInvoiceRequest = pendingInvoicesObjectStore.openCursor();
    await new Promise((resolve, reject) => {
      pendingInvoiceRequest.onsuccess = (event) => {
        const cursor = (event.target as any).result as IDBCursorWithValue;

        if (cursor) {
          const invoice = cursor.value;

          // update invoice products
          const salesInvoiceProducts = invoice.salesInvoiceProducts.data.map(
            (product: any) => ({
              productId: product.productId,
              quantity: product.quantity,
              description: product?.description,
              originalUnitPrice:
                product.originalPriceSubtotal / product.quantity / 100,
              actualUnitPrice:
                product.actualPriceSubtotal / product.quantity / 100,
              taxPercent: product.taxPercent,
              taxIsIncluded: false,
            })
          );

          // update invoice receipt usages
          const salesReceiptInvoiceUsages =
            invoice.salesReceiptInvoiceUsages?.data.map((usage: any) => ({
              ...usage,
              amount: usage.amount / 100,
              actualPaidAmount: usage.actualPaidAmount / 100,
              salesReceipt:
                usage?.salesReceipt?.data != null
                  ? {
                      ...usage.salesReceipt.data,
                      amount: usage.salesReceipt.data.amount / 100,
                      costSubtotal:
                        ((usage.salesReceipt.data as any)?.costSubtotal || 0) /
                        100,
                      taxSubtotal:
                        ((usage.salesReceipt.data as any)?.taxSubtotal || 0) /
                        100,
                    }
                  : undefined,
            }));

          cursor.update({
            orgId: invoice.orgId,
            posId: invoice.posId,

            createdAt: invoice.createdAt,
            issueDate: invoice.issueDate,
            dueDate: invoice.dueDate,

            reference: invoice.reference,
            isCash: invoice.isCash,
            type: "simple",
            customerId: invoice.customerId,

            isAnonymousCustomer: invoice.isAnonymousCustomer,
            callNumber: invoice?.callNumber,
            memberId: invoice?.memberId,
            isNotSynced: true,

            salesInvoiceProducts: salesInvoiceProducts,
            salesReceiptInvoiceUsages: salesReceiptInvoiceUsages ?? undefined,
          } satisfies Invoice);
          cursor.continue();
        } else {
          resolve(null);
        }
      };

      pendingInvoiceRequest.onerror = () => {
        reject(new Error("Failed to iterate over pending invoices"));
      };
    });

    // fix currencies for products
    const productsObjectStore = tx.objectStore("products");
    const productRequest = productsObjectStore.openCursor();

    await new Promise((resolve, reject) => {
      productRequest.onsuccess = (event) => {
        const cursor = (event.target as any).result as IDBCursorWithValue;

        if (cursor) {
          const product = cursor.value as ProductSchema;

          cursor.update({
            ...product,
            salePrice: (product.salePrice || 0) / 100,
            purchasePrice: (product.purchasePrice || 0) / 100,
          } as ProductSchema);

          cursor.continue();
        } else {
          resolve(null);
        }
      };

      productRequest.onerror = () => {
        reject(new Error("Failed to iterate over products"));
      };
    });
  };

  try {
    // Initialize the database with retry mechanism
    db = await connectWithRetry();

    if (!db) {
      throw new Error("Database connection failed");
    }

    if (DB_OLD_VERSION === 2) {
      // backfill currencies fix
      await backfillCurrenciesFix(db);
    }

    return {
      provide: {
        db,
      },
    };
  } catch (error) {
    console.error("FATAL: IndexedDB initialization failed:", error);

    // Show a user-friendly error message
    alert(
      "Critical Error: The application failed to start due to database initialization failure. Please check if your browser supports IndexedDB and try reloading the page."
    );

    // Throw the error to crash the app
    throw error;
  }
});
