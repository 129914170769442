import type { InvoiceSchema } from "@/stores/invoice";
import type { PosSchema } from "@/stores/pos";

type Translations = Record<string, any> | null | undefined;

export interface PrintParams {
  data: {
    // organization
    orgId: string;
    orgDisplayName: PosSchema["pointOfSale"]["organization"]["displayName"];
    orgDisplayNameTranslations: Translations;
    orgTaxNumber: PosSchema["pointOfSale"]["organization"]["taxNumber"];
    orgPhone: PosSchema["pointOfSale"]["organization"]["phone"];
    orgAddress: PosSchema["pointOfSale"]["organization"]["address"];
    orgAddressTranslations: Translations;
    orgCountry: PosSchema["pointOfSale"]["organization"]["country"];
    posSettings?: PosSchema["pointOfSale"]["organization"]["posSettings"];

    // store
    branchName: PosSchema["pointOfSale"]["inventoryStore"]["name"];
    branchNameTranslations: Translations;
    branchAddress: PosSchema["pointOfSale"]["inventoryStore"]["address"];
    branchAddressTranslations: Translations;

    // invoice
    invoiceReference: InvoiceSchema["reference"];
    createdByName: string;

    customerName: string;
    customerNameTranslations: Translations;
    customerTaxNumber?: string;
    customerCrNumber?: string;
    customerAddress?: string;
    customerAddressTranslations?: Translations;

    createdAt: InvoiceSchema["createdAt"];
    issueDate: InvoiceSchema["issueDate"];
    invoiceProducts: {
      id: string;
      name: string;
      nameTranslations: Record<string, any> | null | undefined;
      originalUnitPrice: number;
      actualUnitPrice: number;
      originalPriceSubtotal: number;
      originalTaxSubtotal: number;
      actualPriceSubtotal: number;
      taxSubtotal: number;
      taxIsIncluded: boolean;
      quantity: number;
      barcode?: string;
      taxTypePercent?: number;
      taxTypeDisplayName?: string;
      taxTypeDisplayNameTranslations: Translations;
    }[];
    invoiceReceipts: {
      paymentMethod?: {
        id: string;
        name: string;
        nameTranslations: Translations;
      };
      type: "cash" | "card";
      amount: number;
      actualPaidAmount?: number;
    }[];
    callNumber: number;
    isAnonymousCustomer: boolean;
  };
  options: Omit<TerminalSchema, "printerNames">;
  posLogoUrl?: string | null;
}

export function usePrint() {
  const {
    getMemberName,
    getReceiptPaymentDetails,
    getContactInfo,
    getProductDetails,
  } = useMapIdsToData();
  const router = useRouter();
  const { url: printHelperUrl } = usePrintHelper();

  const origin = window.location.origin;
  const printBaseUrl = `${origin}/print`;
  const posStore = usePosStore();

  const buildQueryHelper = ({ data, options, posLogoUrl }: PrintParams) => {
    // build query params
    return {
      data: JSON.stringify(data),
      options: JSON.stringify(options),
      posLogoUrl: `${posLogoUrl}`,
    };
  };

  const buildQuery = ({ invoice }: { invoice: InvoiceSchema }) => {
    const terminalStore = useTerminalStore();

    if (!posStore.pointOfSale) {
      throw new Error("Point of sale not found");
    }

    const contact = getContactInfo(invoice.customerId);

    const products: PrintParams["data"]["invoiceProducts"] =
      invoice.salesInvoiceProducts?.map((product) => {
        const enrichedProduct = getProductDetails(product.productId);

        return {
          id: product.productId,
          name: enrichedProduct.productName,
          nameTranslations: enrichedProduct.productNameTranslations,
          quantity: product.quantity ?? 0,
          originalUnitPrice: product.originalUnitPrice || 0,
          actualUnitPrice: product.actualUnitPrice || 0,
          originalPriceSubtotal: product.originalUnitPrice * product.quantity,
          originalTaxSubtotal:
            product.originalUnitPrice *
            product.quantity *
            (product.taxPercent / 100),
          actualPriceSubtotal: product.actualUnitPrice * product.quantity,
          taxSubtotal:
            product.actualUnitPrice *
            product.quantity *
            (product.taxPercent / 100),
          taxIsIncluded: product.taxIsIncluded,
          barcode: enrichedProduct.barcode,
          taxTypePercent: enrichedProduct.taxTypePercent
            ? enrichedProduct.taxTypePercent
            : undefined,
          taxTypeDisplayName: enrichedProduct.taxTypePercent
            ? enrichedProduct.taxTypeDisplayName
            : undefined,
          taxTypeDisplayNameTranslations: enrichedProduct.taxTypePercent
            ? enrichedProduct.taxTypeDisplayNameTranslations
            : undefined,
        };
      }) ?? [];

    const receipts: PrintParams["data"]["invoiceReceipts"] =
      invoice.salesReceiptInvoiceUsages?.map((receipt) => {
        const enrichedReceipt = getReceiptPaymentDetails(
          receipt.salesReceipt?.paymentMethodId
        );

        return {
          paymentMethod:
            receipt.salesReceipt?.paymentMethodId && enrichedReceipt
              ? {
                  id: receipt.salesReceipt!.paymentMethodId,
                  name: enrichedReceipt.displayName,
                  nameTranslations: enrichedReceipt.displayNameTranslations,
                }
              : undefined,

          type: !enrichedReceipt
            ? "cash"
            : enrichedReceipt.cashAccountId
              ? "card"
              : "cash",
          amount: receipt.amount ?? 0,
          actualPaidAmount: receipt.actualPaidAmount ?? null,
        };
      }) ?? [];

    const query = buildQueryHelper({
      posLogoUrl: posStore.posLogoUrl,
      data: {
        // organization
        orgId: posStore.pointOfSale.orgId,
        orgDisplayName: posStore.pointOfSale.organization?.displayName,
        orgDisplayNameTranslations:
          posStore.pointOfSale.organization?.displayNameTranslations,
        orgTaxNumber: posStore.pointOfSale.organization?.taxNumber,
        orgPhone: posStore.pointOfSale.organization?.phone,
        orgAddress: posStore.pointOfSale.organization?.address,
        orgAddressTranslations:
          posStore.pointOfSale.organization?.addressTranslations,
        orgCountry: posStore.pointOfSale.organization?.country,
        posSettings: posStore.pointOfSale.organization?.posSettings,

        // store
        branchName: posStore.pointOfSale.inventoryStore?.name,
        branchNameTranslations:
          posStore.pointOfSale.inventoryStore?.nameTranslations,
        branchAddress:
          posStore.pointOfSale.inventoryStore?.address ??
          posStore.pointOfSale.organization?.address,
        branchAddressTranslations:
          posStore.pointOfSale.inventoryStore?.addressTranslations ??
          posStore.pointOfSale.organization?.addressTranslations,

        // invoice
        callNumber: invoice.callNumber ?? 0,
        isAnonymousCustomer: invoice.isAnonymousCustomer ?? false,
        invoiceReference: invoice.reference,
        invoiceProducts: products,
        invoiceReceipts: receipts,

        createdByName: getMemberName(invoice.memberId),

        createdAt: invoice.createdAt,
        issueDate: invoice.issueDate,

        customerName: contact.contactName,
        customerNameTranslations: contact.contactNameTranslations,
        customerTaxNumber: contact.contactTaxNumber,
        customerCrNumber: contact.contactCrNumber,
        customerAddress: contact.contactAddress ?? undefined,
        customerAddressTranslations:
          contact.contactAddressTranslations ?? undefined,
      },
      options: terminalStore.options,
    });
    return query;
  };

  const silentPrint = async ({ invoice }: { invoice: InvoiceSchema }) => {
    const terminalStore = useTerminalStore();

    try {
      if (!printHelperUrl.value) {
        throw new Error("Print helper not found");
      }

      const url = `${printBaseUrl}?${new URLSearchParams(
        buildQuery({ invoice })
      )}`;

      let printers = JSON.parse(
        JSON.stringify(terminalStore.options.printers)
      ) as typeof terminalStore.options.printers;

      while (printers.length > 0) {
        await useFetch<{
          success: boolean;
          error?: string;
        }>(`${printHelperUrl.value}/print`, {
          method: "POST",
          body: JSON.stringify({
            printUrl: url,
            devices: printers.map((printer) => ({
              name: printer.name,
              copies: 1,
            })),
          }),
        });

        printers = printers.filter((printer) => {
          --printer.copies;

          return printer.copies > 0;
        });
      }
    } catch (error) {
      console.error("Error printing invoice:", error);
    }
  };

  const print = ({ invoice }: { invoice: InvoiceSchema }) => {
    const query = buildQuery({ invoice });

    const resolvedRoute = router.resolve({
      name: "print",
      query,
    });
    window.open(resolvedRoute.href, "_blank");
  };

  const getTestPrintUrl = () => {
    const query =
      posStore.posLogoUrl !== undefined
        ? `?${new URLSearchParams({
            posLogoUrl: `${posStore.posLogoUrl}`,
          })}`
        : "";

    return `${printBaseUrl}${query}`;
  };

  return {
    silentPrint,
    print,
    getTestPrintUrl,
  };
}
