import type { SalesInvoicesInsertPOSInputSchema } from "@mono/validation/lib/Sales";
import { useStorage } from "@vueuse/core";
import { useContactStore } from "./contact";
import { useSettingsStore } from "./settings";
import { usePosStore } from "./pos";
import type { IndexedDBSchemas } from "@/composables/useIndexDB";

export type InvoiceSchema = IndexedDBSchemas["invoices"];
export type PendingInvoiceSchema = IndexedDBSchemas["pendingInvoices"];

const MAX_SYNC_INVOICES = 10;

const lastSyncedAt = useStorage<string>(
  "lastSyncedAt",
  new Date().toISOString()
);

export const useInvoiceStore = defineStore("invoice", () => {
  // states
  const invoices = ref<InvoiceSchema[]>([]);
  const pendingInvoices = ref<PendingInvoiceSchema[]>([]);

  const dataIsFetched = ref(false);

  // computes
  const allInvoices = computed(() =>
    invoices.value.concat(
      pendingInvoices.value.filter((invoice) => invoice.isNotSynced)
    )
  );

  // composables
  const { cache } = useIndexDB();

  // fetchData handler
  const fetchData = async () => {
    const { settings } = useSettingsStore();
    const { logout } = useAuthStore();

    if (dataIsFetched.value === true) {
      return;
    }

    try {
      if (!settings) {
        throw new Error("Settings not found");
      }

      const [cachedInvoices, cachedPendingInvoices] = await Promise.allSettled([
        cache.invoices.getAll(),
        cache.pendingInvoices.getAll(),
      ]);

      if (cachedInvoices.status === "fulfilled") {
        invoices.value = cachedInvoices.value;
      }

      if (cachedPendingInvoices.status === "fulfilled") {
        pendingInvoices.value = cachedPendingInvoices.value;
      }

      dataIsFetched.value = true;
    } catch {
      logout();
    }
  };

  // submitInvoice handler
  const submitInvoice = async (invoice: PendingInvoiceSchema) => {
    const data: PendingInvoiceSchema = {
      ...invoice,
      isNotSynced: true,
    };

    // cache invoice
    await cache.pendingInvoices.add(JSON.parse(JSON.stringify(data)));

    pendingInvoices.value.push(data);

    // sync invoices after 15 seconds
    setTimeout(async () => {
      await syncInvoices();
    }, 1000 * 15);
  };

  const clearUploadedInvoices = async () => {
    // get all unsynced invoices (isNotSynced = true)
    const unSyncedInvoices = pendingInvoices.value.filter(
      (invoice) => invoice.isNotSynced === true
    );

    // if the length of unsyncedInvoices is equal to the length of pendingInvoices (NO SYNCED), return early
    if (unSyncedInvoices.length === pendingInvoices.value.length) {
      return;
    }

    // getAllKeys of the pendingInvoices
    const keys = await cache.pendingInvoices.getAllKeys();

    // add the unsyncedInvoices to pendingInvoices
    await cache.pendingInvoices.addMany(unSyncedInvoices);

    // remove the old keys
    await cache.pendingInvoices.deleteMany(keys);

    pendingInvoices.value = unSyncedInvoices;
  };

  // syncInvoice handler
  const syncInvoices = async () => {
    if (pendingInvoices.value.length === 0) {
      return;
    }

    // run the online check
    const { isOnline } = useNetworkCheck();
    await isOnline();

    const { trpcClient } = useTrpcClient();
    const { syncContacts, pendingContacts } = useContactStore();
    const { settings } = useSettingsStore();
    const { posReference, updateCashAccountLastRunningTotal } = usePosStore();

    if (!settings || !posReference) {
      throw new Error("Settings not found");
    }

    // sync contacts before syncing invoices, if pendingContacts exists
    if (pendingContacts.length > 0) {
      await syncContacts();
    }

    try {
      // loop through the pendingInvoices and sync them in chunks
      for (
        let i = 0;
        i < pendingInvoices.value.length;
        i += MAX_SYNC_INVOICES
      ) {
        const chunk = pendingInvoices.value.slice(i, i + MAX_SYNC_INVOICES);

        const { data } = await trpcClient.salesInvoices.createMany.mutate({
          data: chunk as SalesInvoicesInsertPOSInputSchema[],
        });

        // confirm invoices
        await trpcClient.salesInvoices.confirmMany.mutate({
          ids: data.map((invoice) => invoice.id),
        });

        const newInvoices = chunk.map((invoice) => ({
          ...invoice,
          isNotSynced: false,
        }));

        // update invoices
        await cache.invoices.addMany(newInvoices);
        invoices.value = invoices.value.concat(newInvoices);

        // change pendingInvoices to synced
        for (
          let j = i;
          j < i + MAX_SYNC_INVOICES && j < pendingInvoices.value.length;
          j++
        ) {
          pendingInvoices.value[j].isNotSynced = false;
        }
      }
    } catch (error) {
      console.error("Error syncing invoices:", error);
    } finally {
      // update/sync POS counters with DB & cashAccountLastRunningTotal
      // clear the uploaded invoices to avoid duplicates
      await Promise.all([
        clearUploadedInvoices(),
        updateCashAccountLastRunningTotal(),
        trpcClient.pointOfSales.updateOne.mutate({
          id: settings.posId,
          data: {
            posInvoicesReferenceCounter:
              posReference.posInvoicesReferenceCounter,
            posReceiptsReferenceCounter:
              posReference.posReceiptsReferenceCounter,
          },
        }),
      ]);
      lastSyncedAt.value = new Date().toISOString();
    }
  };

  // clearDuplicatePendingInvoices handler
  const clearDuplicatePendingInvoices = async () => {
    await syncInvoices();

    // 2. build up a new pendingInvoices array, that have no duplicates
    const duplicatesSet = new Set();

    const newPendingInvoices: PendingInvoiceSchema[] = [];

    for (let i = 0; i < pendingInvoices.value.length; i++) {
      const invoice = pendingInvoices.value[i];

      const key = `${invoice.reference}-${invoice.issueDate}-${
        invoice.customerId
      }-${invoice.salesInvoiceProducts.reduce(
        (acc, product) =>
          `${acc}-${product.productId}-${product.quantity}-${product.actualUnitPrice}`,
        ""
      )}-${invoice.salesReceiptInvoiceUsages?.length ?? 0}`;

      if (duplicatesSet.has(key)) {
        continue;
      }

      duplicatesSet.add(key);
      newPendingInvoices.push(invoice);
    }

    // if the length of newPendingInvoices is equal to the length of pendingInvoices, return early (no duplicates found)
    if (newPendingInvoices.length === pendingInvoices.value.length) {
      return;
    }

    // 3. update the pendingInvoices cache

    // getAllKeys of the pendingInvoices
    const keys = await cache.pendingInvoices.getAllKeys();

    // add the unsyncedInvoices to pendingInvoices
    await cache.pendingInvoices.addMany(newPendingInvoices);

    // remove the old keys
    await cache.pendingInvoices.deleteMany(keys);

    pendingInvoices.value = newPendingInvoices;
  };

  // deletePendingInvoice handler
  const deletePendingInvoice = async (invoice: {
    reference: string;
    createdAt: string;
    customerId: string;
    salesInvoiceProducts: PendingInvoiceSchema["salesInvoiceProducts"];
    salesReceiptInvoiceUsagesLength: number;
  }) => {
    // if issueDate not older than 2 days, return early
    if (
      invoice.createdAt >
      new Date(new Date().getTime() - 2 * 24 * 60 * 60 * 1000).toISOString()
    ) {
      return;
    }

    const key = `${invoice.reference}-${invoice.createdAt}-${
      invoice.customerId
    }-${invoice.salesInvoiceProducts.reduce(
      (acc, product) =>
        `${acc}-${product.productId}-${product.quantity}-${product.actualUnitPrice}`,
      ""
    )}-${invoice.salesReceiptInvoiceUsagesLength ?? 0}`;

    const newPendingInvoices = pendingInvoices.value.filter(
      (pendingInvoice) => {
        const pendingKey = `${pendingInvoice.reference}-${
          pendingInvoice.createdAt
        }-${
          pendingInvoice.customerId
        }-${pendingInvoice.salesInvoiceProducts.reduce(
          (acc, product) =>
            `${acc}-${product.productId}-${product.quantity}-${product.actualUnitPrice}`,
          ""
        )}-${pendingInvoice.salesReceiptInvoiceUsages?.length ?? 0}`;

        return pendingKey !== key;
      }
    );

    // if the length of newPendingInvoices is equal to the length of pendingInvoices, return early (no duplicates found)
    if (newPendingInvoices.length === pendingInvoices.value.length) {
      return;
    }

    // getAllKeys of the pendingInvoices
    const keys = await cache.pendingInvoices.getAllKeys();

    // add the unsyncedInvoices to pendingInvoices
    await cache.pendingInvoices.addMany(newPendingInvoices);

    // remove the old keys
    await cache.pendingInvoices.deleteMany(keys);

    pendingInvoices.value = newPendingInvoices;
  };

  // clear handler
  const clear = async () => {
    const { settings } = useSettingsStore();

    if (!settings) return;

    await syncInvoices();

    if (allInvoices.value.length > 0) {
      await cache.dumpInvoices.add({
        posId: `${settings.posId}-${new Date().toISOString()}`,
        invoices: JSON.parse(JSON.stringify(allInvoices.value)),
      });
    }

    await Promise.all([cache.invoices.clear(), cache.pendingInvoices.clear()]);
    invoices.value = [];
    pendingInvoices.value = [];
  };

  // init handler
  const init = async () => {
    // fetch invoices first not in parallel to avoid racing condition
    // cuz both mutate the same state
    await fetchData();
    await syncInvoices();
  };

  return {
    // getters
    invoices,
    pendingInvoices,
    allInvoices,
    lastSyncedAt,
    // setters
    clear,
    init,
    fetchData,
    submitInvoice,
    syncInvoices,
    clearDuplicatePendingInvoices,
    deletePendingInvoice,
  };
});
