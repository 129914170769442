import { z } from "zod";
import {
  dateStringSchema,
  emptyStringSchema,
  nonEmptyStringSchema,
  taxNumberSchema,
  translationSchema,
  stringNumbersSchema,
  stringNumbersSchemaRefinement,
} from ".";

const phoneNumberSchemaRefinement = (arg: string, ctx: z.RefinementCtx) => {
  if (new RegExp(/^([0-9+]{7,16}|)$/).test(arg) === false) {
    ctx.addIssue({
      code: "custom",
      params: { i18n: "custom.invalid_phone_number" },
    });
  }
};
const phoneNumberSchema = z.string();

export const contactsBaseInputSchema = z.object({
  id: z.string().uuid().optional(),
  accountId: z.string().uuid(),
  isActive: z.boolean(),
  contactName: nonEmptyStringSchema,
  contactNameTranslations: translationSchema,
  contactOrgName: nonEmptyStringSchema.or(emptyStringSchema),
  contactOrgNameTranslations: translationSchema,
  contactPrimaryEmail: z.string().email().or(emptyStringSchema),
  contactSecondaryEmail: z.string().email().or(emptyStringSchema),
  contactPrimaryPhone: phoneNumberSchema
    .superRefine(phoneNumberSchemaRefinement)
    .or(emptyStringSchema),
  contactSecondaryPhone: phoneNumberSchema
    .superRefine(phoneNumberSchemaRefinement)
    .or(emptyStringSchema),
  contactTaxNumber: taxNumberSchema,
  countryId: z.string(),
  city: z.string().trim().nullish(),
  cityTranslations: translationSchema,
  address: z.string().trim().nullish(),
  addressTranslations: translationSchema,
  zipcode: z.string().nullish(),
  salesPricingPolicyId: z.string().nullish(),
  purchasingPricingPolicyId: z.string().nullish(),
  isBusiness: z.boolean(),
  contactCrNumber: stringNumbersSchema
    .length(10)
    .superRefine(stringNumbersSchemaRefinement)
    .optional(),
});

export const contactsInsertInputSchema = contactsBaseInputSchema.superRefine(
  (data, ctx) => {
    if (
      data.isBusiness === true &&
      (data.contactTaxNumber == null || data.contactTaxNumber == "")
    ) {
      ctx.addIssue({
        code: "invalid_type",
        expected: "string",
        received: "undefined",
        path: ["contactTaxNumber"],
      });
    }
  }
);

export const contactsSetInputSchema = contactsBaseInputSchema
  .partial()
  .superRefine((data, ctx) => {
    if (
      data.isBusiness === true &&
      (data.contactTaxNumber == null || data.contactTaxNumber == "")
    ) {
      ctx.addIssue({
        code: "invalid_type",
        expected: "string",
        received: "undefined",
        path: ["contactTaxNumber"],
      });
    }
  });

export const contactsOpeningBalancesInsertInputSchema = z.object({
  isCustomer: z.boolean(),
  transactionDate: dateStringSchema,
  contactId: z.string().uuid(),
  isConfirmed: z.boolean().optional(),
  description: z.string(),
  contactAccountId: z.string().uuid(),
  openingBalanceAccountId: z.string().uuid(),
  amount: z.number(),
  dimensionIds: z.array(z.string().uuid()).nullish(),
  transactionNote: z.string().nullish(),
  reference: z.string().nullish(),
});

export const contactsOpeningBalancesSetInputSchema =
  contactsOpeningBalancesInsertInputSchema;

export const contactsInsertPosSchema = z.object({
  contactName: nonEmptyStringSchema,
  contactPrimaryEmail: z.string().email().or(emptyStringSchema),
  contactPrimaryPhone: phoneNumberSchema.superRefine(
    phoneNumberSchemaRefinement
  ),
  contactTaxNumber: taxNumberSchema,
  address: z.string().optional(),
  contactCrNumber: stringNumbersSchema
    .length(10)
    .superRefine(stringNumbersSchemaRefinement)
    .optional(),
});

export type ContactsInsertPosSchema = z.infer<typeof contactsInsertPosSchema>;
